
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BackLinks extends Vue {
  @Prop({ type: Boolean, default: false }) communityOnly

  backLinks = [
    {
      name: this.$t('TERMS_OF_SERVICE'),
      href: '/terms-of-service'
    },
    {
      name: this.$t('PRIVACY_POLICY'),
      href: '/privacy-policy'
    },
    {
      name: 'Elitepvpers',
      href: 'https://www.elitepvpers.com/forum/nostale-pserver-advertising/4966630-international-olympus-ascend-opening-20-11-2021-a.html',
      isCommunity: true
    },
    {
      name: 'Cheat-Gam3',
      href: 'https://forum.cheat-gam3.com/threads/international-olympus-ascend.210805/',
      isCommunity: true
    },
    {
      name: 'Inforge',
      href: 'https://www.inforge.net/forum/threads/international-olympus-ascend.608907/',
      isCommunity: true
    }
  ]

  get links() {
    return this.communityOnly ? this.backLinks.filter(e => e.isCommunity) : this.backLinks
  }
}
